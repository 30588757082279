import React from "react";

import { Header } from "../components/parts/Header";
import { TitleView } from "../components/parts/TitleView";
import { Container } from "../components/parts/Container";
import { WhiteWrapper } from "../components/parts/WhiteWrapper";
import { Footer } from "../components/Footer";

import { Layout } from "../components/parts/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <Header
        title={"404 Not Found"}
      />
      <WhiteWrapper>
        <Container>
          <TitleView>ページが見つかりませんでした。</TitleView>
        </Container>
      </WhiteWrapper>
      <Footer />
    </Layout>
  )
}

export default NotFoundPage
